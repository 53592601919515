
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

const TripClosureBtn = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickTripClosureBtn(props);
    };
    return (
        <button
            className='btn btn-warning'
            type="button"
            onClick={handleRouteClick}
            title="Trip Closure"
            style={{
                padding: "0px",
                width: "100%"
            }}
        >
            <i className="icofont icofont-truck-alt black f14"></i> Trip Closure
        </button>
    )
};

export default TripClosureBtn;
