
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

const DeleteBtn = (props) => {
    const handleRouteClick = (e) => {
        e.stopPropagation();
        // props.context.componentParent.onCloseRouteDiv(e);
        props.context.componentParent.onClickDeleteBtn(props);
    };
    var colr = props.data.comment_exists;
    //console.log(props.data,"Component");  
    return (
        <button
            className='btn btn-danger'
            type="button"
            onClick={handleRouteClick}
            title="Delete"
            style={{
                padding: "0px",
                width: "100%"
            }}
        >
            <i className="icofont icofont-edit f14"></i> Delete
        </button>
    )
};

export default DeleteBtn;
